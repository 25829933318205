import React from 'react'
import logoSvg from '../../assets/images/logo.svg';
import { Link } from 'react-router-dom'
function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-4 order-4 order-md-1 order-lg-4 order-xl-1">
                        <div className="footer__logo">
                            <img src={logoSvg} alt="" />
                        </div>
                        <p className="footer__tagline">Our mission is simple to provide reliable, affordable, and hassle-free car rentals to travelers </p>


                    </div>

                    <div className="col-6 col-md-4 col-lg-3 col-xl-2 order-1 order-md-2 order-lg-2 order-xl-3 offset-md-2 offset-lg-0">
                        <h6 className="footer__title">Company</h6>
                        <div className="footer__nav">
                            <Link to={'/about'}>About us</Link>
                            <a href="">Tariffs</a>
                            <a href="https://hirecabs.in/blog">Our blog</a>
                            <Link to={'/contact-us'}>Contact us</Link>
                        </div>
                    </div>

                    <div className="col-12 col-md-8 col-lg-6 col-xl-4 order-3 order-md-3 order-lg-1 order-xl-2">
                        <div className="row">
                            <div className="col-12">
                                <h6 className="footer__title">Services</h6>
                            </div>

                            <div className="col-6">
                                <div className="footer__nav">
                                    <a href="https://hirecabs.in/">Local Car Rentals</a>
                                    <a href="https://hirecabs.in/">Outstation Taxi</a>
                                    <a href="https://hirecabs.in/">One way cabs</a>
                                    <a href="https://hirecabs.in/">Corporate Car Rental</a>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="footer__nav">
                                    <a href="https://hirecabs.in/">Airport Taxi</a>
                                    <a href="https://hirecabs.in/">Round trip taxi</a>
                                    <a href="https://hirecabs.in/">Office Cabs</a>
                                    <a href="https://hirecabs.in/">Hotel Cabs</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6 col-md-4 col-lg-3 col-xl-2 order-2 order-md-4 order-lg-3 order-xl-4">
                        <h6 className="footer__title">Support</h6>
                        <div className="footer__nav">

                            <Link to={'/help'}>Help</Link>
                            <Link to={'/contact-us'}>Contact Us</Link>
                            <Link to={'/privacy-policy'}>Privacy Policy</Link>
                            <Link to={'/term-conditions'}>Terms & Conditions</Link>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="footer__content">
                            <small className="footer__copyright">©2024 All Rights Reserved by MEDHYA TECHNOLOGIES.</small>
                            <div className="footer__social">
                                <a href="https://www.facebook.com/profile.php?id=61566974160026" target="_blank" rel="noopener noreferrer">
                                    <box-icon type='logo' name='facebook'></box-icon>
                                </a>
                                <a href="https://www.facebook.com/profile.php?id=61566974160026" target="_blank" rel="noopener noreferrer">
                                    <box-icon type='logo' name='instagram'></box-icon>
                                </a>
                                <a href="https://www.linkedin.com/feed/update/urn:li:activity:7249822972117487617/" target="_blank" rel="noopener noreferrer">
                                    <box-icon name='linkedin' type='logo' ></box-icon>
                                </a>
                                <a href="https://www.youtube.com/@Hirecabs" target="_blank" rel="noopener noreferrer">
                                    <box-icon name='youtube' type='logo' ></box-icon>
                                </a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer