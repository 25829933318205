import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";
import SchemaOrg from '../../components/schema.org/SchemaOrg';
function About() {
    return (
        <>
            <SchemaOrg />
            <Helmet>
                <title>About Us - HireCabs
                </title>


                <link rel="canonical" href="https://www.hirecabs.in/about" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />


            </Helmet>

            <main className="main">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ul className="breadcrumbs">
                                <li className="breadcrumbs__item"><Link to={'/'}>Home</Link></li>
                                <li className="breadcrumbs__item breadcrumbs__item--active">About Us</li>
                            </ul>
                        </div>

                        <div className="col-12 col-xl-11">
                            <div className="main__title main__title--page">
                                <h1>About HireCabs</h1>

                                <p>Welcome to HireCabs, your trusted partner for reliable, safe, and convenient transportation. Founded with a mission to simplify travel, we specialize in providing top-notch cab services tailored to meet the diverse needs of our customers. Whether you’re commuting to work, heading to the airport, or exploring a new city, HireCabs ensures a smooth, hassle-free ride every time.</p>

                                <p>At HireCabs, we pride ourselves on our commitment to excellence. Our fleet of well-maintained vehicles, driven by professional and courteous drivers, guarantees not only your safety but also your comfort. We understand that time is of the essence, which is why our services are designed to be prompt and efficient, getting you where you need to be, right on time.</p>
                                <p>Our easy-to-use booking platform allows you to schedule your rides in advance or book on-demand, giving you flexibility and control over your travel plans. We also offer transparent pricing with no hidden fees, so you know exactly what you’re paying for.</p>
                                <p>Join the thousands of satisfied customers who have made HireCabs their go-to choice for transportation. Experience the difference with HireCabs – where your journey matters.</p>
                            </div>
                        </div>

                    </div>


                </div>
            </main>
        </>
    )
}

export default About