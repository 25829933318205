import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";
import SchemaOrg from '../../components/schema.org/SchemaOrg';
function Terms() {
    return (
        <>
            <SchemaOrg />
            <Helmet>
                <title>Terms and Conditions- HireCabs | Terms HireCabs  | Terms Page Hirecabs
                </title>
                <link rel="canonical" href="https://www.hirecabs.in/term-conditions" />

            </Helmet>
            <main className="main">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ul className="breadcrumbs">
                                <li className="breadcrumbs__item"><Link to={'/'}>Home</Link></li>
                                <li className="breadcrumbs__item breadcrumbs__item--active">Terms & Conditions</li>
                            </ul>
                        </div>

                        <div className="col-12 col-xl-11 termspage">
                            <div className="main__title main__title--page">
                                <p>Welcome to Hirecabs! By using our website, you agree to comply with and be bound by the following terms and conditions of use. Please review the following terms carefully. If you do not agree with any part of these terms and conditions, please do not use our website.</p>
                            </div>
                            <br />
                            <h2>Terms of Use</h2>
                            <p>Your use of this website is subject to the following terms of use:</p>
                            <h3>1. General Information and Use</h3>
                            <li>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
                            <h3>Cookies</h3>
                            <li>This website uses cookies to monitor browsing preferences. If you do allow cookies to be used, the following personal information may be stored by us for use by third parties: IP Address, Location.</li>

                            <h3>No Warranties</h3>
                            <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness, or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors, and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
                            <h3>User Responsibility</h3>
                            <li>
                                Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services, or information available through this website meet your specific requirements.
                            </li>
                            <h3>Intellectual Property</h3>
                            <p>This website contains material owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</p>
                            <h3>Trademarks</h3>
                            <li>All trademarks reproduced on this website, which are not the property of or licensed to the operator, are acknowledged on the website.</li>
                            <h3>Unauthorized Use</h3>
                            <li>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.</li>
                            <h3>Links to Other Websites</h3>
                            <li>From time to time, this website may include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</li>
                            <h3>Governing Law</h3>
                            <p>Your use of this website and any dispute arising out of such use of the website is subject to the laws of India.</p>
                            <h3>Additional Terms & Conditions</h3>
                            <p>Specific offers may have additional Terms & Conditions which the user must comply with if they choose to avail themselves of that offer.</p>

                            <h2>Cancellation and Returns</h2>

                            <p>You may cancel the booking up to 1 hour before the journey without any cancellation charges for all services. However, last-minute cancellations might cause a loss to the driver. Therefore, we recommend canceling as soon as you learn of the change in your travel plans. You can cancel your booking online or through the Hiecabs App.</p>

                            <h2>Refunds</h2>
                            <p>If you are eligible for refunds based on the “Cancellation and Returns” policy above, the refund will be remitted back to you within 5-7 working days. In case of any issues, write to us at info@hirecabs.in or call us at 99722 57154.</p>
                        </div>

                    </div>
                </div>
            </main >
        </>
    )
}

export default Terms