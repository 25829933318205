import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { homePageService, useGetKmByLocMutation, useStoreOrderMutation, useRegUserMutation } from '../../services/homePageService';
import acsvg from '../../assets/images/air-conditioner.svg'
import seatsvg from '../../assets/images/seat.svg';
import baggage from '../../assets/images/baggage.svg';
import priceTag from '../../assets/images/pricetag.svg';
import driver from '../../assets/images/driver.svg';
import toll from '../../assets/images/toll.svg';
import tax from '../../assets/images/tax.svg';
import parking from '../../assets/images/parking.svg';
import mapsvg from '../../assets/images/map.svg'
import termssvg from '../../assets/images/terms.svg'
import * as moment from 'moment'
import rightarrow from '../../assets/images/breadcrumb.svg';
import { useNavigate } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { toast } from 'react-toastify';
import { otppopupStatusSet } from '../../services/otpSlice';
import { useSelector, useDispatch } from 'react-redux';
import LoginPopup from '../../components/LoginPopup/LoginPopup';
import { titleCase } from '../../components/Utilities/Utilities';
import { loggedInStatusSet } from '../../services/loggedinslice';
import { currencyFormat } from '../../components/Utilities/Utilities';

function CarDetail() {

    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('');
    const location = useLocation();
    const carData = location.state !== null ? location.state.carData : [];
    const bookData = location.state !== null ? location.state.bookData : [];
    const totalKm = location.state !== null ? location.state.totalKm : [];

    const dispatch = useDispatch();
    const popupState = useSelector(state => state.loginPopup.value);

    if (location.state === null) {
        navigate('/');
    }
    const [createOrder] = useStoreOrderMutation();
    const [createUser] = useRegUserMutation();
    const [buttonText, setButtonText] = useState('Submit');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [emailDisabled, setEmailDisabled] = useState(false);
    const [rider_name, setName] = useState(bookData.rider_name ? bookData.rider_name : '');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState(bookData.phone ? bookData.phone : '');
    const [addr, setAddr] = useState('');

    const handleNameChange = (event) => {
        setName(event.target.value);
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }
    const [isLoading, setIsLoading] = useState(false);
    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    }

    const handleAddrChange = (event) => {
        setAddr(event.target.value);
    }

    // State to track selected radio option

    // Handler for when a radio button is selected
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };


    const handleStoreData = async () => {
        try {

            bookData.pickupDate = moment(bookData.pickupDate).format('YYYY-MM-DD HH:mm:ss');
            if (bookData.returnDate) {
                bookData.returnDate = moment(bookData.returnDate).format('YYYY-MM-DD HH:mm:ss');
            }

            if (bookData.toLocationAll) {
                bookData.toLocation.locationName = bookData.toLocation.locationName.split(',').slice(0, 2).join(' ').replace(/  +/g, ' ');
                bookData.toLocationAll.forEach((element, index) => {
                    bookData.toLocation.locationName += (index == 0 ? "|" : "") + element.locationName.split(',').slice(0, 2).join(' ').replace(/  +/g, ' ') + (bookData.toLocationAll.length - 1 != index ? "|" : "");

                });
            }

            bookData.totalKm = totalKm;
            let pdata = {
                rider_name: rider_name,
                rider_email: email,
                rider_phone: phone,
                pickup_address: addr,
                car: carData,
                order: bookData,
                paymentType: selectedOption
            }

            // Dispatch the mutation with the new resource data
            const bookResult = await createOrder(pdata);

            if (!bookResult.data.success) {
                toast.error(bookResult.data.msg);
                navigate(`/`);
            }
            else if (bookResult.data.success) {
                if (bookResult.data.online) {
                    window.location = bookResult.data.url;
                } else {
                    toast.success('Booked Successfully!');
                    dispatch(loggedInStatusSet(true));
                    navigate(`/myaccount`);
                }
            }

            // setCarData(carData => [...carData, { totalKm: result.data }]);
        } catch (error) {
            // Handle error
            console.error('Error creating resource:', error);
        }
        finally {
            setIsLoading(false) //to turn off loading
        }
    };

    const createAccount = async () => {

        try {
            let pdata = {
                name: rider_name,
                email: email,
                mobile: phone,
                spassword: '',
                type: 2
            }
            setIsLoading(true);
            // Dispatch the mutation with the new resource data
            const result = await createUser(pdata);

            if (!result.data.status) {
                toast.error(result.data.message);
                return
            }
            else if (result.data.status) {
                let resData = result.data;
                localStorage.setItem('name', resData.name)
                localStorage.setItem('email', resData.email)
                localStorage.setItem('phone', resData.phone)
                localStorage.setItem('token', resData.token)
                dispatch(loggedInStatusSet(true));
                handleStoreData();
            }

            // setCarData(carData => [...carData, { totalKm: result.data }]);
        } catch (error) {
            // Handle error
            console.error('Error creating resource:', error);
        }
        finally {
            setIsLoading(false) //to turn off loading

        }
    }

    const submitOrder = (e) => {

        if (rider_name === '') {
            toast.error('Please enter name');
            return false;
        }
        if (email === '') {
            toast.error('Please enter email');
            return false;
        }
        if (email !== '') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                toast.error('Please enter valid email');
                return;
            }
        }
        if (phone === '') {
            toast.error('Please enter phone');
            return false;
        }

        if (phone !== '') {
            const phoneRegex = /^\d{10}$/;

            if (!phoneRegex.test(phone)) {
                toast.error('Please enter valid phone');
                return;
            }
        }

        if (addr === '') {
            toast.error('Please enter address');
            return false;
        }

        if (selectedOption === '') {
            toast.error('Please select payment type');
            return false;
        }

        if (localStorage.getItem('token') === null) {
            createAccount();
            return;
        }


        setIsLoading(true);
        setIsButtonDisabled(true);

        // Change button text
        setButtonText('Please Wait...');

        // Your button click logic here
        // Example: Simulating an asynchronous action
        // setTimeout(() => {
        //     // Reset button text and enable the button after some time
        //     setButtonText('Book Now');
        //     setIsButtonDisabled(false);
        // }, 10000);

        handleStoreData();

    }


    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            dispatch(otppopupStatusSet(true));
        } else {
            setName(localStorage.getItem('name') || '');
            setEmail(localStorage.getItem('email') || '');
            setPhone(localStorage.getItem('phone') || '');
        }

    }, [dispatch, addr, carData, bookData]);


    if (isLoading) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    }


    return (
        <>
            <main className="main">
                <div className="container">
                    <section className="row">

                        <div className="col-12">
                            <ul className="breadcrumbs">
                                <li className="breadcrumbs__item"><Link to={'/'}>Home</Link></li>
                                <li className="breadcrumbs__item breadcrumbs__item--active">Booking Summary</li>
                            </ul>
                        </div>

                        <div className="col-12 col-lg-7">

                            <div className="booking-form">

                                <div className='book-form-wrap'>
                                    <span className="offer__title" >  Pickup Details</span>


                                    <div className="form-group">
                                        {/* <label>Name</label> */}
                                        <input type="text" className="sign__input" placeholder="Name" value={rider_name} onChange={handleNameChange} disabled={rider_name} />
                                    </div>


                                    <div className="form-group">
                                        {/* <label>E-Mail</label> */}
                                        <input onChange={handleEmailChange} type="text" value={email} className="sign__input" placeholder="E-Mail" />
                                    </div>

                                    <div className="form-group">
                                        {/* <label>Phone</label> */}
                                        <input type="text" className="sign__input" placeholder="Phone" disabled={phone} onChange={handlePhoneChange} value={phone} />
                                    </div>

                                    <div className="form-group text-area">
                                        {/* <label>Address</label> */}
                                        <textarea rows={3} onChange={handleAddrChange} value={addr} placeholder='complete Address' />
                                    </div>
                                </div>

                            </div>

                            <div className='terms__tab'>
                                <div className='termstabwrap'>
                                    <span className="offer__title">  Info & Terms</span>
                                    <Tabs>
                                        <TabList>
                                            <Tab>Inclusions</Tab>
                                            <Tab>Exclusions</Tab>
                                            <Tab>Facilities</Tab>
                                            <Tab>T&C</Tab>
                                        </TabList>

                                        <TabPanel className='flex__list fare_breakup'>
                                            <li>
                                                <img src={priceTag} style={{ width: '20px', height: 'auto' }} />
                                                &nbsp; Base Fare for {totalKm} km
                                            </li>
                                            <li>
                                                <img src={driver} style={{ width: '20px', height: 'auto' }} />        &nbsp;  Driver Allowance
                                            </li>
                                            <li>
                                                <img src={toll} style={{ width: '20px', height: 'auto' }} />
                                                &nbsp;  State Tax & Toll
                                            </li>
                                            <li>
                                                <img src={tax} style={{ width: '20px', height: 'auto' }} />
                                                &nbsp;
                                                GST (5%)
                                            </li>
                                        </TabPanel>
                                        <TabPanel className='flex__list'>
                                            <li>
                                                <img src={priceTag} style={{ width: '20px', height: 'auto' }} />
                                                &nbsp;
                                                Pay ₹{carData.extra_per_km_charges_with_gst_company}/km after {totalKm} Km
                                            </li>
                                            <li>
                                                <img src={parking} style={{ width: '20px', height: 'auto' }} />  &nbsp;
                                                Multiple pickups/drops
                                            </li>
                                            <li>
                                                <img src={parking} style={{ width: '20px', height: 'auto' }} />  &nbsp;
                                                Airport Entry/Parking
                                            </li>

                                        </TabPanel>
                                        <TabPanel className='flex__list'>
                                            <li>
                                                <img src={seatsvg} style={{ width: '20px', height: 'auto' }} />
                                                &nbsp;
                                                4 seater
                                            </li>
                                            <li>
                                                <img src={baggage} style={{ width: '20px', height: 'auto' }} />   2 bags
                                            </li>
                                            <li>
                                                <img src={acsvg} style={{ width: '20px', height: 'auto' }} />
                                                &nbsp; AC
                                            </li>

                                        </TabPanel>
                                        <TabPanel className='termspoints'>
                                            <li>Your Trip has a KM limit. If your usage exceeds this limit, you will be charged for the excess KM used.
                                            </li>
                                            <li>The Airport Entry/Parking charge, if applicable is not included in the fare and will be charged extra.
                                            </li>
                                            <li>Your trip includes one pick up in Pick-up city and one drop to destination city. It does not include within city travel.
                                            </li>
                                            <li>If your Trip has Hill climbs, cab AC may be switched off during such climbs.
                                            </li>
                                        </TabPanel>

                                    </Tabs>
                                </div>
                            </div>

                        </div>

                        <div className="col-12 col-lg-5">
                            <div className="offer">
                                <span className="offer__title">Summary</span>
                                <ul className="offer__list">
                                    <li>
                                        <span className="offer__list-name">Itinerary</span>
                                        <span className="offer__list-value offer__list-value--dark right-align" >

                                            {bookData.fromLocation.locationName.split(',').slice(0, 2).join(' ')} <img src={rightarrow} className='img__as__icon' /> {bookData.toLocation.locationName.split(',').slice(0, 2).join(' ')}

                                            {!bookData.returnDate && (
                                                <>
                                                    &nbsp;(One Way)
                                                </>
                                            )}


                                            {bookData.toLocationAll.map((item, index) => (

                                                <>
                                                    <img src={rightarrow} className='img__as__icon' />
                                                    <span> {item.locationName.split(',').slice(0, 1)}
                                                    </span>
                                                </>
                                            ))}

                                            {bookData.returnDate && (
                                                <>
                                                    <img src={rightarrow} className='img__as__icon' />
                                                    {bookData.fromLocation.locationName.split(',').slice(0, 1).join(' ')} (Round Trip)
                                                </>
                                            )}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="offer__list-name">Pickup Date</span>
                                        <span className="offer__list-value offer__list-value--dark">{moment(bookData.pickupDate).format('DD-MMM-YYYY, hh:mm A')}</span>
                                    </li>
                                    {bookData.returnDate && (
                                        <li>
                                            <span className="offer__list-name">Return Date</span>
                                            <span className="offer__list-value offer__list-value--dark">{moment(bookData.returnDate).format('DD-MMM-YYYY, hh:mm A')}</span>
                                        </li>
                                    )}

                                    <li>
                                        <span className="offer__list-name">Car Type</span>
                                        <span className="offer__list-value offer__list-value--dark">{titleCase(carData.car_name)}</span>
                                    </li>


                                </ul>

                            </div>

                            <div className="offer">
                                <span className="offer__title">Payment</span>
                                <ul className="offer__list">
                                    {carData.discountAmt && carData.discountAmt > 0 && (
                                        <li>
                                            <span className="offer__list-name">Base Total</span>
                                            <span className="offer__list-value offer__list-value--dark">Rs. {carData.total_net} </span>
                                        </li>
                                    )}
                                    {/* <li>
                                        <span className="offer__list-name">Total GST</span>
                                        <span className="offer__list-value offer__list-value--dark">Rs. {carData.totslGST_amt}</span>
                                    </li> */}

                                    {carData.discountAmt && carData.discountAmt > 0 && (

                                        <li>
                                            <span className="offer__list-name">Discount</span>
                                            <span className="offer__list-value offer__list-value--dark">Rs. {carData.discountAmt}</span>
                                        </li>

                                    )}

                                    <li>
                                        <span className="offer__list-name">Grand Total</span>
                                        <span className="offer__list-value offer__list-value--dark">Rs. {carData.totalAmount}</span>
                                    </li>

                                    <li>
                                        <label className="custom-radio"  >
                                            <input type="radio"
                                                value="online" onClick={handleOptionChange}
                                                checked={selectedOption === 'online'}
                                            />

                                            <span className="checkmark"></span>
                                            Pay Online (<b> {currencyFormat(carData.totalAmount)}</b>)
                                        </label>
                                    </li>
                                    <li>
                                        <label className="custom-radio">
                                            <input type="radio"
                                                value="offline"
                                                checked={selectedOption === 'offline'}
                                                onClick={handleOptionChange} />

                                            <span className="checkmark"></span>
                                            Pay Driver (<b>{currencyFormat(carData.totalAmount)}</b>)
                                        </label>
                                    </li>

                                </ul>



                                <div className="offer__wrap" style={{ flexFlow: 'column' }}>
                                    <button disabled={isButtonDisabled} onClick={(e) => submitOrder(e)} type="button" className="offer__rent"><span> {buttonText}</span></button>
                                </div>

                            </div>
                        </div>


                    </section>
                </div>


            </main>

        </>
    )
}

export default CarDetail