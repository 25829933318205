import React from 'react';


export const titleCase = (str) => {
    return str.toLowerCase().split(' ').map(function (word) {
        return word.replace(word[0], word[0]?.toUpperCase());
    }).join(' ');
}

export const currencyFormat = (num) => {
    const formattedNum = num.toFixed(2);
    if (parseFloat(formattedNum) % 1 === 0) {
        return '₹ ' + Math.floor(num).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else {
        return '₹ ' + formattedNum.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
}
