import React from 'react'
import { Link } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion';
import { Helmet } from "react-helmet";
import SchemaOrg from '../../components/schema.org/SchemaOrg';

function Help() {
    return (
        <>
            <SchemaOrg />
            <Helmet>
                <title>Hire Cabs FAQ HireCabs |  HireCabs Hire Cabs FAQ | Hire Cabs FAQ Page Hirecabs
                </title>
                <link rel="canonical" href="https://hirecabs.in/help" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <main className="main">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ul className="breadcrumbs">
                                <li className="breadcrumbs__item"><Link to={'/'}>Home</Link></li>
                                <li className="breadcrumbs__item breadcrumbs__item--active">Frequently asked questions</li>
                            </ul>
                        </div>

                        <div className="col-12 col-xl-11">
                            <div className="main__title main__title--page">
                                <h1>Help center</h1>
                                <p>Here you will find answers to frequently asked questions about our company and services. If you don't find the answer to your question, please contact our customer support and we'll be happy to assist you.        <Link to={'/contact-us'}>Ask a question</Link></p>
                            </div>
                        </div>

                        <div className="col-12 accordin_in_help">
                            <div className='row'>
                                <div className='col-12 col-lg-6'>
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Which cars are available for rent?</Accordion.Header>
                                            <Accordion.Body>
                                                We offer SEDAN, HATCHBACK, SUV, Compact & Hybrid Cars
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header> What's the trip's kilometer limit, and what if I exceed it?</Accordion.Header>
                                            <Accordion.Body>
                                                Your Trip has a KM limit. If your usage exceeds this limit, you will be charged for the excess KM used
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>  Is the Airport Entry/Parking charge included in the fare?</Accordion.Header>
                                            <Accordion.Body>
                                                No, the Airport Entry/Parking charge is not included in the fare and will be charged separately.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>

                                <div className='col-12 col-lg-6'>
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header> Can I request multiple pickups during my trip?</Accordion.Header>
                                            <Accordion.Body>
                                                No, your trip includes one pick-up and one drop-off to the destination. It does not include multiple pickup if required need to selected diffrent locations during booking
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header> Will the cab's air conditioning be turned off during hill climbs on my trip?</Accordion.Header>
                                            <Accordion.Body>
                                                If your Trip has Hill climbs, cab AC may be switched off during such climbs
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header> Can I add additional cities to my itinerary during the trip?</Accordion.Header>
                                            <Accordion.Body>
                                                To ensure smooth travel, please include all cities you plan to visit in your itinerary before the trip begins. Adding cities during the trip may not be possible.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </main>
        </>
    )
}

export default Help